export default {
  methods: {
    hasRule (ruleKey) {
      if (!this.company || !this.company.rules) {
        return false
      }
      return this.company.rules.find((rule) => rule === ruleKey)
    }
  }
}
